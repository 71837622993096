





















































































































import {
  BarcodeScanner,
  SupportedFormat,
} from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';
import LoginFacade from '../domain/LoginFacade';

export default Vue.extend({
  name: 'Login',
  props: {
    name: { type: String },
    animation: { type: Object },
    minVersion: { type: String },
  },
  data: () => ({
    webserviceUrl: AuthService.authority ?? '',
    loading: false,
    error: null as null | 'unreachable' | 'outdated' | 'servermissing',
    errorShown: false,
    scannerShown: false,
  }),
  computed: {
    canScan() {
      return Capacitor.isNativePlatform();
    },
  },
  deactivated() {
    this.stopScan();
  },
  beforeDestroy() {
    this.stopScan();
  },
  methods: {
    async scan() {
      await BarcodeScanner.checkPermission({ force: true });
      document.querySelector('body')?.classList.add('scanner-active');
      BarcodeScanner.hideBackground();

      const timeout = setTimeout(() => {
        this.stopScan();
      }, 10000);
      const result = await BarcodeScanner.startScan({
        targetedFormats: [SupportedFormat.QR_CODE],
      });

      if (result.hasContent) {
        clearTimeout(timeout);
        const prefix = 'ConsolidateWebserviceUrl:';
        if (result.content.startsWith(prefix)) {
          this.webserviceUrl = result.content.substr(prefix.length);
          this.scannerShown = false;
          this.login();
        }
      }
    },
    stopScan() {
      document.querySelector('body')?.classList.remove('scanner-active');
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    },
    async login() {
      this.loading = true;

      const result = await LoginFacade.login(
        this.webserviceUrl,
        this.minVersion
      );
      if (result !== true) {
        this.error = result;
        this.errorShown = true;
      }

      this.loading = false;
    },
  },
});
